<template>
  <div class="caseinfo">
     <div class="content_breadcrumb">
      <div class="content_nav">
        当前位置&nbsp;： 新闻资讯 &nbsp;&nbsp; >&nbsp;&nbsp;
        <span class="content_detail">详细内容</span>
      </div>
    </div>
    <div class="cotainer">
      <div class="header">
        <h1>地铁项目</h1>
        <p>时间：2019-2.21</p>
      </div>
        <div class="introduce mt_30">
            <p>建模软件：Revit,Tekla,3DMax,Civi3D</p>
            <p>地形数据：倾斜摄影</p>
            <p>模型文件：20 GB</p>
            <p>倾斜摄影：322 GB</p>
            <p>地形精度：5 CM</p>
        </div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(4)_dt.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(4)_dt2.png" alt=""></div>
      <div class="pic"><img src="../../assets/image/cases/case_bj(4)_dt3.png" alt=""></div>
    </div>
</div>
</template>

<script>
export default {
  mounted () {
      document.querySelector('.nav').style.backgroundColor = '#000'
  },
  destroyed () {
      document.querySelector('.nav').style.backgroundColor = 'transparent'
  }
}
</script>

<style lang="less" scoped>
.mt_30{
  margin-top: 30px;
}
.caseinfo{
  width: 100%;
  // height: 100%;
  .cotainer{
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    width: 100%;
    line-height: 30px;
    h1{
      font-weight: normal;
    }
    h2{
      line-height: 50px;
    }
    .header{
      p{
        color: rgba(51,51,51,1);
        opacity: 0.7;
      }
    }
  }
}
</style>
